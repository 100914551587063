import React from 'react'
import img from '../images/last.png'

function Lastpage() {
  return (
    <div className="">
    <div className="row">
     <div className="col-12">
       <img src={img} style={{width:"inherit"}}   alt="" />
     </div>

    </div>
    
   </div>
  )
}

export default Lastpage